import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { AvailableAsWebApp, Android, IPhone } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="text-xl">
  Open Skiguide App by scanning or clicking the QR-code below
    </p>
    <Link className="block mt-20 mb-12 cursor-pointer ml-20" to="https://skiguide.app" target="skiguide" mdxType="Link">
  <AvailableAsWebApp url="https://skiguide.app" label="skiguide.app" style={{
        transform: "scale(1.7)"
      }} mdxType="AvailableAsWebApp" />
    </Link>
    <br />
    <p className="text-xl">Installing Skiguide on your iPhone</p>
    <br />
    <div className="flex flex-wrap justify-left">
  <IPhone alt="Open skiguide.app in Safari" img="/howto/skiguide_install_1.png" className="sm:w-1/4 m-4" text="1) Using Safari, open skiguide.app and click the middle icon at the bottom of the screen" mdxType="IPhone" />
  <IPhone alt="Slide the box upwards" img="/howto/skiguide_install_2.png" className="sm:w-1/4 m-4" text="2) In the share box that opens you will find the Add to Home Screen option" mdxType="IPhone" />
  <IPhone alt="Slide the box upwards and click Add to Home Screen" img="/howto/skiguide_install_3.png" className="sm:w-1/4 m-4" text="3) Slide the box upwards to reveal the Add to Home Screen option and click it" mdxType="IPhone" />
    </div>
    <br />
    <p className="text-xl">Installing Skiguide on your Android phone</p>
    <br />
    <div className="flex flex-wrap justify-left">
  <Android alt="Open skiguide.app in Chrome" img="/howto/skiguide-install-android-1.png" className="sm:w-1/4 m-4" text="1) Using Chrome, open skiguide.app and click the three dots in the top right corner" mdxType="Android" />
  <Android alt="Click the Install app menu item" img="/howto/skiguide-install-android-2.png" className="sm:w-1/4 m-4" text="2) Click the Install app menu item" mdxType="Android" />
  <Android alt="Click the Install button" img="/howto/skiguide-install-android-3.png" className="sm:w-1/4 m-4" text="3) Click the Install button" mdxType="Android" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      